var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.tableOptions,
          gridRowBtn: _vm.gridRowBtn,
          tableData: _vm.historyColumns,
          "data-total": _vm.total,
          "table-loading": _vm.loading,
        },
        on: {
          "page-size-change": _vm.handleSizeChange,
          "page-current-change": _vm.handleCurrentChange,
        },
        scopedSlots: _vm._u([
          {
            key: "start_time",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getDate(row.start_time)) +
                    "\n      "
                ),
              ]
            },
          },
          {
            key: "end_time",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n        " + _vm._s(_vm.getDate(row.end_time)) + "\n      "
                ),
              ]
            },
          },
          {
            key: "elapsedTime",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getElapsedTime(row.start_time, row.end_time)) +
                    "\n      "
                ),
              ]
            },
          },
          {
            key: "statusName",
            fn: function ({ row }) {
              return [
                _c("el-tag", { attrs: { type: _vm.historyTagColor(row) } }, [
                  _vm._v(_vm._s(_vm.historyTagText(row))),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }