<template>
   
    <div>
 
    
    
        <!-- <el-table
          :data="triggersList"
          style="width: 100%;height: 70vh;overflow-y: scroll;"
          border="true"
        >
          <el-table-column
            prop="trigger_id"
            label="ID"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="flowName"
            label="任务名称"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="projectName"
            align="center"
            label="项目">
          </el-table-column>
          <el-table-column
            prop="firstScheduledRun"
            align="center"
            label="第一次计划运行">
          </el-table-column>
          <el-table-column
            prop="nextExecutionTime"
            align="center"
            label="下次执行时间">
          </el-table-column>
          <el-table-column
            prop="cronExpression"
            align="center"
            label="表达式">
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="操作">
            <template slot-scope="scope">

            



<el-button
          @click="delesDS(scope.row)"
          type="text"
          size="small">
          删除
        </el-button>


        




      </template>


          </el-table-column>

          

        </el-table> -->


        <grid-layout ref="gridLayOut"
      :tableOptions="tableOptions"
      :tableData="triggersList"
      :data-total="total"
      :table-loading="loading"
      @page-size-change="handleSizeChange" @page-current-change="handleCurrentChange"
      :gridRowBtn="gridRowBtn"
      @grid-del="delesDS" 
        >

      <!-- <template slot="modified_time" slot-scope="{row}" >
        {{ getDate(row.modified_time) }}
      </template> -->

    </grid-layout>





        <!-- <div class="block" >
    
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listParms.startIndex"
      :page-sizes="[20,50,100]"
      :page-size="20"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div> -->



        
 
 
 
 
 
    <!-- 弹框 -->
    <el-dialog
  :title="$t('cip.dc.taskScheduling.title.Tips')"
  :visible.sync="dialog1"
  width="30%"
  >
  <span>{{ $t('cip.dc.taskScheduling.msg.msg2') }}</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialog1 = false">{{ $t('projectlist.btn.close') }}</el-button>
    <el-button type="primary" @click="MdelesDS">{{ $t('projectlist.btn.sure') }}</el-button>
  </span>
</el-dialog>
 
 
 
 </div>
   </template>
   
   <script>
   import {getTriggers,delScheduleByScheduId} from "@/api/dataAcquisition";
   import eventBus from "@/util/eventBus"
   import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
   import GridLayout from "@/views/components/layout/grid-layout";

   export default {
     name: "task3",
     data(){
       return{
        loading: false,
         dialog1:false,
         dialog2:false,
         dialog3:false,
         
         triggersList:[],
         total:"", //总数
   listParms:{
  perPage: 20, startIndex: 1
},
page: {
          pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
          currentPage: 1,
          total: 0
        },
      gridRowBtn:[
      {
            label: this.$t('projectlist.btn.delete'),
            emit: "grid-del",
            type: "text",
            icon: ""
          },

         
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 300,
        height:625,
          column: [
          {
            label: this.$t('cip.dc.taskScheduling.label3.ID'),
            prop: "trigger_id",
            cell: true,
          },
          
          {
            label: this.$t('cip.dc.taskScheduling.label3.Taskname'),
            prop: "flowName",
            cell: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label3.project'),
            prop: "projectName",
            cell: true,
          },
            
            {
              label: this.$t('cip.dc.taskScheduling.label3.Firstscheduledrun'),
              prop: "firstScheduledRun",
             
            },
            {
            label: this.$t('cip.dc.taskScheduling.label3.Nextexecutiontime'),
            prop: "nextExecutionTime",
            cell: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label3.expression'),
            prop: "cronExpression",
            cell: true,
          },
          ]
        },
         
 
       }
     },
     components:{
   GridLayout
  },
     mounted(){
      this.getTriggersList(this.listParms)
      eventBus.$on("Sthird",res=>{
        this.getTriggersList(this.listParms)
    })

     },
     created(){
        
     },
     methods:{
        getTriggersList(e){
      getTriggers(e).then(res=>{
        this.triggersList = res.data.data.list
        this.total = res.data.data.total
      })
    },
    //删除定时任务
    delesDS(e){
      console.log(e);
      this.dialog1 = true
      this.DSid = e.trigger_id
    },
    MdelesDS(){
      delScheduleByScheduId({id:this.DSid}).then(res=>{
        this.getTriggersList(this.listParms)
        this.dialog1 = false
      })
    },

    //分页
    handleSizeChange(val) {
        this.listParms.perPage = val.pageSize
        this.getTriggersList(this.listParms)
      },
      handleCurrentChange(val) {
        this.listParms.startIndex = val.currentPage
        this.getTriggersList(this.listParms)
      }
     
     }
   }
   </script>
   
   <style scoped>
  .block{
  float:right;
}
   </style>
   