var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.tableOptions,
          tableData: _vm.ExecutorList,
          "data-total": _vm.total,
          "table-loading": _vm.loading,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "page-size-change": _vm.handleSizeChange,
          "page-current-change": _vm.handleCurrentChange,
          "grid-kill": _vm.killFlow,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function ({ row }) {
              return [
                _c("el-tag", { attrs: { type: _vm.historyTagColor(row) } }, [
                  _vm._v(_vm._s(_vm.historyTagText(row))),
                ]),
              ]
            },
          },
          {
            key: "start_time",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n       " + _vm._s(_vm.getDate(row.start_time)) + "\n     "
                ),
              ]
            },
          },
          {
            key: "end_time",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n       " + _vm._s(_vm.getDate(row.end_time)) + "\n     "
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.dc.taskScheduling.title.Tips"),
            visible: _vm.dialog1,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog1 = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("cip.dc.taskScheduling.msg.msg3"))),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog1 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("projectlist.btn.close")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog1 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("projectlist.btn.sure")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }