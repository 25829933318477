var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.taskScheduling.name"),
        },
      }),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.dc.taskScheduling.tabs.Projectlist"),
                name: "first",
              },
            },
            [_c("task1")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.dc.taskScheduling.tabs.Historylist"),
                name: "second",
              },
            },
            [_c("task2")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.dc.taskScheduling.tabs.Scheduledtasklist"),
                name: "third",
              },
            },
            [_c("task3")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.dc.taskScheduling.tabs.Runninglist"),
                name: "fourth",
              },
            },
            [_c("task4")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }